/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.transfer {
  position: relative;
  line-height: 1.5;
  display: flex;
  align-items: center;
}
.transfer-list {
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  vertical-align: middle;
  position: relative;
  outline: none;
}
.transfer-list div {
  outline: none;
}
.transfer-list li {
  list-style-type: none;
  list-style-position: outside;
  list-style-image: none;
}
.transfer-list-header {
  padding: 0px 15px;
  line-height: 33px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  width: 100%;
}
.transfer-list-header-title {
  position: absolute;
  right: 15px;
}
.transfer-list-search-wapper {
  padding: 0px 4px;
  line-height: 38px;
  position: relative;
}
.transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 26px;
  text-align: center;
  font-size: 16px;
}
.transfer-list-search-action .anticon {
  transition: all .3s;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}
.transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.transfer-list-search-action {
  pointer-events: none;
}
.transfer-list-content-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 15px;
  min-height: 32px;
  transition: all .3s;
}
.transfer-list-content-item-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.transfer-list-content-item:not(.transfer-list-content-item-disabled):hover {
  cursor: pointer;
  background-color: #e6f7ff;
}
.transfer-list-body-not-found {
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 100%;
}
.transfer-list-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.transfer-list-virtualized {
  width: 100% !important;
}
.transfer-list .ReactVirtualized__Grid__innerScrollContainer {
  width: 100% !important;
  max-width: 100% !important;
}
.transfer-operation {
  overflow: hidden;
  margin: 0 8px;
  flex: none;
}
.transfer-operation .ant-btn {
  display: block;
}
.transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.transfer-operation .ant-btn .anticon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
